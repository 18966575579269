<template>
  <div id="employeeVacation" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-2"
        >SOLICITUD DE VACACIONES</v-row
      >
      <v-container fluid class="addForms" style="margin-top: 1% !important">
        <v-row no-gutters justify="end">
          <v-col
            cols="12"
            lg="5"
            md="5"
            sm="5"
            class="marginCol formulariosTarget"
          >
            <v-row>
              <div class="form-group">
                <label for="enterprise">Empresa</label>
                <select
                  name="enterprise"
                  id="enterprise"
                  v-model="enterprise"
                  @change="obtenerAreas()"
                >
                  <option selected disabled value="0">
                    Selecciona Empresa
                  </option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <!--Area-->
                <label for="area">Área</label>
                <select
                  name="area"
                  id="area"
                  v-model="area"
                  @change="obtenerPuestos()"
                >
                  <option selected disabled value="0">Selecciona Area</option>
                  <option
                    v-for="area in areas"
                    :key="area.id"
                    v-bind:value="area.id"
                  >
                    {{ area.nombreArea }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <!--Puesto-->
                <label for="job">Puesto</label>
                <select
                  name="job"
                  id="job"
                  v-model="job"
                  @change="obtenerEmpleados()"
                >
                  <option selected disabled value="0">Selecciona Puesto</option>
                  <option
                    v-for="job in jobs"
                    :key="job.id"
                    v-bind:value="job.id"
                  >
                    {{ job.nombrePuesto }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <!--Empleados-->
                <label for="employee">Empleado</label>
                <select
                  name="employee"
                  id="employee"
                  v-model="employee"
                  @change="calcularVacaciones()"
                >
                  <option selected disabled value="0">
                    Selecciona Empleado
                  </option>
                  <option
                    v-for="employee in employees"
                    :key="employee.id"
                    v-bind:value="employee.id"
                  >
                    {{ employee.nombre }} {{ employee.apellidoPaterno }}
                    {{ employee.apellidoMaterno }}
                  </option>
                </select>
              </div>
              <!--Dias con derecho-->
              <div class="form-group">
                <label for="diasCorrespondientes">Días con derecho</label>
                <input
                  type="text"
                  name="diasCorrespondientes"
                  id="diasCorrespondientes"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="diasCorrespondientes"
                  required
                  disabled
                />
              </div>
              <!--Inicio de vacaciones-->
              <div class="form-group">
                <label for="inicio">Inicio de vacaciones</label>
                <input
                  type="date"
                  name="inicio"
                  id="inicio"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="inicio"
                  :min="fecha_minimo"
                  @blur="validacionFechas()"
                  required
                />
              </div>
              <!--Fin de vacaciones-->
              <div class="form-group">
                <label for="fin">Fin de vacaciones</label>
                <input
                  type="date"
                  name="fin"
                  id="fin"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="fin"
                  @change="consultaCalculoVacaciones()"
                  :min="fecha_minimo"
                  @blur="validacionFechas()"
                  required
                />
              </div>
              <!--Dias solicitados-->
              <div class="form-group">
                <label for="diasSolicitados">Días solicitados</label>
                <input
                  type="text"
                  name="diasSolicitados"
                  id="diasSolicitados"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="diasSolicitados"
                  required
                  readonly
                  @keypress="isNumber($event)"
                />
              </div>
              <!--Dias restantes-->
              <div class="form-group">
                <label for="diasRestantes">Días restantes</label>
                <input
                  type="text"
                  name="diasRestantes"
                  id="diasRestantes"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="diasRestantes"
                  required
                  @keypress="isNumber($event)"
                />
              </div>
              <v-row
                ><v-col cols="12" sm="6" md="6" lg="6" class="">
                  <label for="actividades">Actividades pendientes</label></v-col
                >
                <v-col cols="12" sm="3" md="3" lg="5" class="pa-0">
                  <textarea
                    type="text"
                    name="actividades"
                    id="actividades"
                    placeholder="Obligatorio"
                    class="inputs mt-1"
                    autocomplete="false"
                    v-model="actividades"
                    required
                  />
                </v-col>
              </v-row>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="regresar()">
                Cancelar
              </button>
              <button class="botonAmarilloDerecho" @click="validacion()">
                Guardar
              </button>
            </v-row></v-col
          >
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer
          ><router-link to="/requests">
            <v-btn color="success" text @click="confirmation = false"
              >OK</v-btn
            ></router-link
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
      message: "",
      errors: [],
      error: false,
      respuesta: "",
      confirmation: false,
      advice: false,
      diasSolicitados: 0,
      inicio: 0,
      fin: 0,
      areas: [],
      area: 0,
      jobs: [],
      job: 0,
      employees: [],
      employee: 0,
      actividades: "",
      inicio: "",
      fin: "",
      diasCorrespondientes: 0,
      diasRestantes: 0,
      fecha_minimo: "",
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacionFechas() {
      if (this.inicio != "" && this.fin == "")
        console.log("solo se lleno fecha inicial");
      if (this.fin != "" && this.inicio == "")
        console.log("solo se lleno fecha final");
      if (this.inicio != "" && this.fin != "") {
        console.log("ambas fechas tienen valors");
        if (this.inicio > this.fin) {
          this.message = "Fechas Solicitadas";
          this.respuesta =
            "La fecha de inicio debe ser diferente, favor de corregirlo";
          this.error = true;
        }
      }
    },
    obtenerAreas() {
      this.areas = [];
      this.area = 0;
      this.job = 0;
      this.employee = 0;
      this.show = true;
      this.diasRestantes = 0;
      this.diasCorrespondientes = 0;
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerPuestos() {
      this.jobs = [];
      this.employee = 0;
      this.job = 0;
      this.show = true;
      this.diasRestantes = 0;
      this.diasCorrespondientes = 0;
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (
              value.empresaId == this.enterprise &&
              value.areaReportaId == this.area
            ) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEmpleados() {
      this.employees = [];
      this.employee = 0;
      this.show = true;
      this.diasRestantes = 0;
      this.diasCorrespondientes = 0;
      axios
        .get(Server + "/empleados", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var todosEmpleados = response.data;
          todosEmpleados.forEach((element) => {
            if (
              element.empresaId == this.enterprise &&
              element.puestoId == this.job &&
              element.areaId == this.area
            ) {
              this.employees.push(element);
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    validacion() {
      this.errors = [];
      if (this.employee == 0) {
        this.errors.push("Se debe elegir el empleado.");
      }
      if (this.inicio.length == 0) {
        this.errors.push(
          "Se debe especificar la fecha de inicio de vacaciones."
        );
      }
      if (this.fin.length == 0) {
        this.errors.push(
          "Se debe especificar la fecha de inicio de vacaciones."
        );
      }
      if (this.diasSolicitados == 0) {
        this.errors.push("Se deben especificar los días solicitados.");
      }
      if (this.actividades == "" || this.actividades == " ") {
        this.errors.push("Se deben especificar las actividades pendientes.");
      }
      if (this.errors.length == 0) {
        this.message = "SOLICITUD";
        this.respuesta = "Esta seguro de agregar la solicitud de vacaciones.";
        this.advice = true;
      } else {
        this.message = "SOLICITUD";
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    regresar() {
      this.$router.push("/requests");
    },
    cancelar() {
      this.inicio = "";
      this.fin = "";
      this.enterprise = 0;
      this.area = 0;
      this.job = 0;
      this.employee = 0;
      this.diasSolicitados = 0;
      this.diasCorrespondientes = 0;
      this.diasRestantes = 0;
      this.actividades = "";
      this.advice = false;
    },
    guardar() {
      this.advice = false;
      var fechaActual = new Date();
      var fechaSolicitud =
        fechaActual.getFullYear() +
        "/" +
        (fechaActual.getMonth() + 1) +
        "/" +
        fechaActual.getDate() +
        " " +
        fechaActual.getHours() +
        ":" +
        fechaActual.getMinutes() +
        ":" +
        fechaActual.getSeconds();
      this.show = true;
      axios
        .post(
          Server + "/vacaciones",
          {
            EmpleadoSolicitaId: this.employee,
            FechaInicioVacaciones: this.inicio,
            FechaFinVacaciones: this.fin,
            DiasSolicitados: this.diasSolicitados,
            ActividadesPendientes: this.actividades,
            FechaSolicitud: fechaSolicitud,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.inicio = "";
          this.fin = "";
          this.diasSolicitados = 0;
          this.employee = 0;
          this.job = 0;
          this.enterprise = 0;
          this.area = 0;
          this.actividades = "";
          this.diasRestantes = 0;
          this.diasCorrespondientes = 0;
          this.message = "Confirmación";
          this.respuesta = "La solicitud fue almacenada con éxito";
          this.confirmation = true;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.inicio = "";
            this.fin = "";
            this.diasSolicitados = 0;
            this.employee = 0;
            this.job = 0;
            this.enterprise = 0;
            this.area = 0;
            this.actividades = "";
            this.diasRestantes = 0;
            this.diasCorrespondientes = 0;
            this.message = "Aviso";
            this.respuesta = "Las solicitud no fue almacenada.";
            this.confirmation = true;
          }
        });
    },
    calcularVacaciones() {
      this.empleado = localStorage.empleadoId;
      localStorage.empleadoId = "";
      this.show = true;
      axios
        .get(Server + "/vacaciones/por-empleado/" + this.employee, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          /* (this.diasVacaciones = response.data.diasCorresponde),
            (this.diasDisfrutados = response.data.diasDisfrutados),
            (this.diasDisponibles = response.data.diasPorDisfrutar); */
          this.diasCorrespondientes = response.data.diasPorDisfrutar;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    consultaCalculoVacaciones() {
      if (this.inicio != "" && this.fin != "" && this.employee > 0) {
        this.show = true;
        axios
          .post(
            Server + "/vacaciones/calcular",
            {
              EmpleadoId: this.employee,
              FechaInicio: this.inicio,
              FechaFin: this.fin,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            // console.log(response);
            this.diasSolicitados = response.data;
            this.diasRestantes =
              this.diasCorrespondientes - this.diasSolicitados;
            this.show = false;
          })
          .catch((e) => {
            this.show = false;
            console.log(e);
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      }
    },
    fechasMinimo() {
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var dia = fecha.getDate();
      var _mes = fecha.getMonth(); //0 al 11
      _mes = _mes + 1; //de 1 al 12
      if (_mes < 10) {
        //agrega un 0
        var mes = "0" + _mes;
      } else {
        var mes = _mes.toString();
      }
      this.fecha_minimo = anio + "-" + mes + "-" + dia; // Nueva variable
    },
  },
  created() {
    this.fechasMinimo();
    this.listar();
    this.obtenerAreas();
  },
};
</script>